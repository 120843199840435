<template>
  <div>
    <b-card
      title="รายชื่อสมาชิก"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="วันที่"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="fetchReport"
              >
                ดูรายงาน
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-table
        ref="reportTable"
        striped
        hover
        responsive
        class="position-relative"
        :items="statdata"
        :fields="fields"
      >
        <template #cell(username)="data">
          {{ data.item.phoneno }}<br>
          {{ data.item.username }}
        </template>
        <template #cell(fullname)="data">
          {{ `${data.item.fname} ${data.item.lname}` }}
        </template>
        <template #cell(bank_acc_no)="data">
          <img
            :src="getBankImage(data.item.bank_code)"
            style="width: 24px; margin-bottom:5px"
          > {{ data.item.bank_name }}<br>{{ `${data.item.bank_acc_no}` }}
        </template>
        <template #cell(credit)="data">
          <div style="text-align: right;">
            {{ `${data.item.wallet.credit}` }}
          </div>
        </template>
        <template #cell(get_promo)="data">
          <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
            {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
          </b-badge>
        </template>
        <template #cell(invited_by)="data">
          <b-badge
            v-if="!data.item.invited_by"
            :variant="'light-danger'"
          >
            ไม่มี
          </b-badge>
          <div v-if="data.item.invited_by">
            {{ data.item.refer_by.phoneno }}<br>
            {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
          </div>
        </template>
        <template #cell(status)="data">
          <div style="text-align:center;">
            <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
              {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
            </b-badge><br>
          </div>
        </template>
        <template #cell(btn_detail)="data">
          <div style="text-align:center;">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="viewMember(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              แก้ไขข้อมูล
            </b-button>
          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BButton, BCardBody, BRow, BCol, BForm, BFormInput, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    // Cleave,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    // vSelect,
    flatPickr,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statdata: [],
      tableQuery: {
        page: 1,
        page_size: 100,
        startdate: moment().startOf('month').format('YYYY-MM-DD'),
        enddate: moment().endOf('month').format('YYYY-MM-DD'),
      },
      fields: [
        {
          key: 'date',
          label: 'วันที่',
          formatter: val => moment(val).format('DD/MM/YYYY'),
        },
        {
          key: 'member_new',
          label: 'สมัครใหม่',
          formatter: val => parseInt(val),
        },
        {
          key: 'member_new_deposit',
          label: 'สมัครใหม่เติมเงิน',
          formatter: val => parseInt(val),
        },
        {
          key: 'member_all_active',
          label: 'เข้าเล่น',
          formatter: val => parseInt(val),
        },
        {
          key: 'total_deposit',
          label: 'ฝากรวม',
          formatter: val => this.numberWithCommas(val),
          tdClass: 'td-currency',
        },
        {
          key: 'total_withdraw',
          label: 'ถอนรวม',
          formatter: val => this.numberWithCommas(val),
          tdClass: 'td-currency',
        },
        {
          key: 'total_promotion',
          label: 'จ่ายโปรรวม',
          formatter: val => this.numberWithCommas(val),
          tdClass: 'td-currency',
        },
        {
          key: 'total_winloss',
          label: 'ยอดวินลอส',
          formatter: val => this.numberWithCommas(val),
          tdClass: 'td-currency',
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
    // Set the initial number of items
    this.fetchReport()
  },
  methods: {
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    saveToExcel(dataToExports) {
      // import('@/libs/Export2Excel').then(excel => {
      //   let list = []
      //   if (dataToExports === 'new_members')
      //     list = this.new_members
      //   else if (dataToExports === 'walkin_members')
      //     list = this.walkin_members
      //   else if (dataToExports === 'active_members')
      //     list = this.active_members
      //   else if (dataToExports === 'firsttopup_members')
      //     list = this.firsttopup_members
      //   else if (dataToExports === 'comeback_members')
      //     list = this.comeback_members
      //   else if (dataToExports === 'inactive_members')
      //     list = this.inactive_members
      //   const headerTitle = ['เบอร์โทร', 'ยูสเซอร์', 'ชื่อ', 'นามสกุล', 'รหัสธนาคาร', 'เลขที่บัญชี', 'วันที่สมัคร', 'เข้าเล่นล่าสุด']
      //   const headerVal = ['phoneno', 'username', 'fname', 'lname', 'bank_code', 'bank_acc_no', 'created_at', 'updated_at']
      //   const data = this.formatJson(headerVal, list)
      //   excel.export_json_to_excel({
      //     header: headerTitle,
      //     data,
      //     filename: `${dataToExports}-${this.tableQuery.startdate}`,
      //     autoWidth: true,
      //     bookType: 'xlsx',
      //   })
      //   this.clearFields()
      // })
    },
    fetchReport(mode) {
      this.$http.post('report/webstats', { startdate: this.tableQuery.startdate, enddate: this.tableQuery.enddate }).then(({
        data: {
          success, stats,
        },
      }) => {
        this.statdata = stats
      })
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      // this.$refs.membertable.refresh()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.td-currency {
  text-align: right;
}
</style>
